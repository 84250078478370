<style scoped>
.cardFooter {
	display: inline-block;
	font-weight: 700;
	color: #fff;
	background-color: #00a09c;
	border-color: #00a09c;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
}
.error {
	color: red;
}
.cardTextBody {
	min-height: 230px;
}
.imgHeight {
	height: 165px;
	object-fit: contain;
}
</style>

<template>
	<div>
		<b-col md="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
				{{ FormMSG(7, 'Carbon removal solution') }}
			</h1>
		</b-col>

		<div class="card px-3 py-4">
			<b-col cols="12">
				<h4>
					<b>{{ FormMSG(1, 'Project current green impact (CO2): ') }} {{ portfolios.totalCO21 }}</b>
				</h4>
				<p>
					<b>{{ FormMSG(2, 'Remove ') }}</b
					>{{ FormMSG(3, 'your residual footprint with carbon removal solutions selected & managed by our partner Klimate.') }}
				</p>
			</b-col>
			<b-col cols="12">
				<div :class="[`${!$screen.md ? 'justify-content-around mx-2' : 'd-flex justify-content-around mx-2'}`]">
					<b-col
						v-for="portfolio in portfolios.porfolio"
						:key="portfolio.id"
						:cols="`${!$screen.md ? '12' : '3'}`"
						:class="[`${!$screen.md ? 'mb-3' : ''}`]"
					>
						<div>
							<div class="border rounded-top rounded-bottom border-primary" style="height: 100%">
								<div class="pt-4 px-2">
									<div class="mb-2">
										<img
											:src="portfolio.imageXid || 'https://pocketsolutionsltd.files.wordpress.com/2012/10/grey-landscape-background2.png'"
											class="w-100 imgHeight"
											alt=""
										/>
									</div>
									<div class="text-left cardTextBody w-90 m-auto">
										<h5>
											<b>{{ portfolio.name }}</b>
										</h5>
										<p v-html="portfolio.description"></p>
									</div>
								</div>
								<div class="text-right pr-3" style="background: #8080802b; padding: 10px; margin-top: 10px">
									<router-link :to="`/carbon-removal/${portfolio.id}`" class="text-decoration-none"
										><h6 style="color: black; margin: 0px">
											<b><i class="icon-eye font-weight-bold"></i>{{ FormMSG(4, '  View Details') }}</b>
										</h6></router-link
									>
								</div>
								<div class="cardFooter rounded-bottom px-2 w-100">{{ FormMSG(5, 'Cost: ') }}{{ portfolio.pricePerTon }}{{ currency }}/ton</div>
							</div>
							<button type="button" class="btn btn-outline-primary w-100 mt-3" @click="toggleModal(portfolio)">
								{{ FormMSG(6, 'Subscribe') }}
							</button>
						</div>
					</b-col>
				</div>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				ref="modal"
				v-model="isOpen"
				size="lg"
				hide-footer
				:title="this.FormMSG(14, 'Carbon removal subscription')"
				centered
				modal-class="mui-animation"
				:fade="false"
			>
				<b-container class="overflow-hidden">
					<div>
						<b-col cols="12">
							<b-row>
								<div class="text-left">
									<h5>
										<b>{{ FormMSG(1, 'Project current green impact (CO2): ') }} {{ portfolios.totalCO21 }}</b>
									</h5>
								</div>
							</b-row>
							<div class="modalody">
								<b-row>
									<b-col sm="12" md="12" lg="4" xl="4" :class="[`${!$screen.md ? 'px-0' : 'px-0 mr-5'}`]">
										<b-form-group>
											<label for="solution">
												<b>{{ this.FormMSG(10, 'Choosen solution') }}</b>
											</label>
											<b-form-input
												v-model="choosenSolution"
												type="text"
												id="solution"
												:placeholder="FormMSG(122, 'Certified nature based solutions')"
												disabled
											></b-form-input>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" class="px-0">
										<b-form-group>
											<label for="cost">
												<b>{{ this.FormMSG(11, 'Solution cost') }}</b>
											</label>
											<b-form-input
												v-model="solutionCost"
												type="text"
												id="cost"
												:placeholder="FormMSG(13, '15€/ton')"
												disabled
											></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" md="12" lg="4" xl="4" :class="[`${!$screen.md ? 'px-0' : 'px-0 mr-5'}`]">
										<b-form-group>
											<label for="desire">
												<b>{{ this.FormMSG(15, 'Number of ton desired*') }}</b>
											</label>
											<b-form-input
												step="0.01"
												:state="isValid"
												@update="getValue"
												:max="portfolios.totalCO2 / 1000"
												min="0"
												v-model="desiredValue"
												type="number"
												:placeholder="FormMSG(15, 'Number of ton desired*')"
											></b-form-input>
											<small v-if="!isValid" class="error">{{ FormMSG(100, 'Invalid Number (only 2 decimal allowed)') }}</small>
										</b-form-group>
										<p v-if="!$screen.md">
											{{ this.FormMSG(18, 'The quantity desired is set by default with the number of Ton of your project.') }} <br />
											{{ this.FormMSG(19, 'You can change it by the number of ton you want to compensate.') }}
										</p>
									</b-col>
									<b-col sm="12" md="12" lg="4" xl="4" class="px-0">
										<b-form-group>
											<label for="total">
												<b>{{ this.FormMSG(16, 'Total cost') }}</b>
											</label>
											<b-input-group>
												<b-form-input
													v-model="totalAmount"
													type="number"
													id="total"
													:placeholder="FormMSG(16, 'Total cost')"
													disabled
												/>
												<b-input-group-append>
													<b-input-group-text>
														<b>{{ currency }}</b>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<p v-if="$screen.md">
										{{ this.FormMSG(18, 'The quantity desired is set by default with the number of Ton of your project.') }} <br />
										{{ this.FormMSG(19, 'You can change it by the number of ton you want to compensate.') }}
									</p>
									<p>
										<b>{{ this.FormMSG(20, 'Note') }}</b>
										{{
											this.FormMSG(
												21,
												'After confirming your subscription, you will receive an offer by email with additional instructions.'
											)
										}}
										<br />
										{{ this.FormMSG(22, 'Your carbon removal certificate will be sent to you at the end of the process.') }}
									</p>
								</b-row>
							</div>
							<b-row class="flex">
								<b-col sm="0" md="0" lg="4" xl="4" class="px-0"> </b-col>
								<b-col lg="4" xl="4" md="6" class="px-0 text-center w-50">
									<b-button class="w-75" @click="onCancel" variant="outline-primary">{{ this.FormMSG(23, 'Cancel') }}</b-button>
								</b-col>
								<b-col lg="4" xl="4" md="6" class="px-0 text-center w-50">
									<b-button v-if="$screen.md" class="w-75" :disabled="!isValid" @click="onSubmit">{{
										this.FormMSG(24, 'Confirm subscription')
									}}</b-button>
									<b-button v-else class="w-75" :disabled="!isValid" @click="onSubmit">{{ this.FormMSG(254, 'Confirm') }}</b-button>
								</b-col>
							</b-row>
						</b-col>
					</div>
				</b-container>
			</b-modal>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { store } from '@/store';
const query_postfolios = gql`
	query {
		GetPortfolios {
			id
			totalCO2
			porfolio {
				id
				createdAt
				updatedAt
				name
				description
				projects {
					id
					createdAt
					updatedAt
					share
					name
					description
					offsetMethodName
					offsetMethodImageXid
					offsetMethodDescription
					projectImageXid
					portfolioId
				}
				pricePerTon
				imageXid
				climateImpactScore
			}
			updatedAt
			createdAt
		}
	}
`;
const mutation_subscription = gql`
	mutation ($PorfolioId: ID!, $DesiredValue: Float!, $TotalAmount: Float!) {
		SendKlimateValidationEmailToTGSSales(PorfolioId: $PorfolioId, DesiredValue: $DesiredValue, TotalAmount: $TotalAmount)
	}
`;

export default {
	name: 'CarbonRemovalList',
	mixins: [languageMessages],

	data() {
		return {
			isOpen: false,
			portfolios: {},
			choosenSolution: '',
			solutionCost: 0,
			desiredValue: 1,
			totalAmount: 0,
			choosenId: 0,
			isValid: true,
			currency: '€'
		};
	},
	created() {
		this.loadData();
		const currencySymbol = (store && store.state && store.state.myProfile && store.state.myProfile.currencySymbol) || '€';
		this.currency = currencySymbol;
	},
	methods: {
		getValue(e) {
			const ex = /^(?:\d*\.\d{1,2}|\d+)$/;
			let desire = parseFloat(Math.floor(e * 100) / 100);
			if (ex.test(e) == false) {
				this.isValid = false;
			} else {
				this.isValid = true;
			}
			if (desire > this.portfolios.totalCO2 / 1000) {
				desire = Math.round((this.portfolios.totalCO2 / 1000) * 100) / 100;
				this.desiredValue = Math.round((this.portfolios.totalCO2 / 1000) * 100) / 100;
			}
			if (desire == 0) {
				return this.desiredValue;
			} else {
				this.desiredValue = desire;
			}
			let total = Math.round(desire * parseFloat(this.solutionCost) * 100) / 100;
			this.totalAmount = total;
		},
		toggleModal(data) {
			this.choosenSolution = data.name;
			this.choosenId = parseInt(data.id);
			this.solutionCost = data.pricePerTon + this.currency + ' /ton';
			this.desiredValue = Math.round((this.portfolios.totalCO2 / 1000) * 100) / 100;
			this.totalAmount = data.pricePerTon * this.desiredValue;
			this.isOpen = true;
		},
		onCancel() {
			this.isOpen = false;
		},
		async onSubmit() {
			await this.$apollo
				.mutate({
					mutation: mutation_subscription,
					variables: {
						PorfolioId: parseInt(this.choosenId),
						DesiredValue: parseFloat(this.desiredValue),
						TotalAmount: parseFloat(this.totalAmount)
					}
				})
				.then((result) => {
					if (result) {
						this.$toast({
							message: this.FormMSG(53, 'Your subscription has been sent successfully'),
							title: this.FormMSG(56, 'Success'),
							variant: 'success'
						});
						this.isOpen = false;
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		loadData() {
			this.$apollo
				.query({
					query: query_postfolios,
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					const data = Object.assign({}, result.data.GetPortfolios);
					data.totalCO21 =
						data.totalCO2 > 1000 ? `${numeral(data.totalCO2 / 1000).format('0,0.00')} T` : `${numeral(data.totalCO2).format('0,0.00')} Kg`;
					this.portfolios = data;
					this.desiredValue = data.totalCO2 / 1000;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		}
	}
};
</script>
